import React from 'react';
import styled from 'styled-components';
import { Heading2 } from '../../components/ui/Headings/Headings.styled';
import { Alert, Button } from 'antd';
import Link from 'antd/lib/typography/Link';

export const NOT_LOGGED_IN_ERROR = 'notLoggedIn';
export const ON_BUSINESS_PROFILE_ERROR = 'onBusinessProfile';
export const INCORRECT_CREDENTIALS_ERROR = 'incorrectCredentials';
export const FACEBOOK_LOGIN_PAGE = 'https://www.facebook.com/';

const MainWrapper = styled.section`
  height: 100%;
  width: 100%;
  padding-top: 31.77vh;
  display: flex;
  justify-content: center;
`;

const MainContainer = styled.div`
  width: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExtensionErrorRedirect: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const queryObject = Object.fromEntries(query.entries());
  const queryError = queryObject.error;

  let errorBanner;
  if (queryError === NOT_LOGGED_IN_ERROR) {
    errorBanner = <FacebookLoginPrompt />;
  }
  if (queryError === ON_BUSINESS_PROFILE_ERROR) {
    errorBanner = <FacebookBusinessPrompt />;
  }

  if (queryError === INCORRECT_CREDENTIALS_ERROR) {
    errorBanner = <IncorrectLoginPrompt />;
  }

  return (
    <MainWrapper>
      <MainContainer>
        <Heading2>We're sorry</Heading2>
        {errorBanner}
      </MainContainer>
    </MainWrapper>
  );
};

export const FacebookLoginPrompt = () => {
  return (
    <Alert
      message="You need to login to Facebook!"
      description="To resume posting to Facebook Marketplace, make sure you are logged into Facebook on your computer."
      type="error"
      showIcon
      action={
        <Link href={FACEBOOK_LOGIN_PAGE} target="_blank">
          <Button danger>Login to Facebook</Button>
        </Link>
      }
    />
  );
};

export const FacebookBusinessPrompt = () => {
  return (
    <Alert
      message="You need to switch back to your personal profile."
      description="Make sure you are logged into Facebook and switched to your personal profile and not your Business Page."
      type="error"
      showIcon
      action={
        <Link href={FACEBOOK_LOGIN_PAGE} target="_blank">
          <Button danger>Switch to Personal Profile</Button>
        </Link>
      }
    />
  );
};

export const IncorrectLoginPrompt = () => {
  const description = (
    <div>
      <br />
      <p>
        Make sure your login credentials are entered into the extension correctly and be sure to log back into Facebook.
      </p>
      <p>
        If you are still having trouble please reach out to{' '}
        <a href="mailto:support@localshift.io">support@localshift.io</a>
      </p>
    </div>
  );

  return (
    <Alert
      message="We were unable to log you back into Facebook."
      description={description}
      type="error"
      showIcon
      action={
        <Link href={FACEBOOK_LOGIN_PAGE} target="_blank">
          <Button danger>Login to Facebook</Button>
        </Link>
      }
    />
  );
};

export default ExtensionErrorRedirect;
