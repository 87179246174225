import React from 'react';
import { message, Modal, Tooltip } from 'antd';
import styled from 'styled-components';
import { copyTextToClipboard } from '../../../utils/clipboard.utils';
import mainTheme from '../../StyleGuide/themes/mainTheme';
import AccountInfoCard from '../AccountInfoCard/AccountInfoCard';
import IconButtonSimple from '../IconButtonSimple';
import { TagLabel } from '../TagLabel';
import { NameId } from '../../utils/types';
import { displayGqlErrors } from '../ErrorList';
import * as Sentry from '@sentry/react';
import { snackbar } from '../Snackbar';
import { useMutation } from '@apollo/client';
import { SendMarketplaceOnboardingEmailRequest } from '../../../graphql/mutations/sendMarketplaceOnboardingEmail';
import { MailOutlined } from '@ant-design/icons';
import { SEND_DASHBOARD_INVITE_EMAIL } from '../../../graphql/mutations/sendDashboardInviteEmail';

const { confirm } = Modal;

const UserListItemWrapper = styled.div`
  width: 100%;
  padding: 16px 0px 16px 16px;
  box-shadow: ${(props) => props.theme.shadows.l};
  border: solid 1px rgba(0, 0, 0, 0.07);
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
`;
const UserDetailCol = styled.div`
  flex-grow: 1;
`;
const IconCol = styled.div`
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LocationCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
interface IconButtonSympleProps {
  active: boolean;
  activeColor: string;
}

const IconButtonStyled = styled(IconButtonSimple)<IconButtonSympleProps>`
  color: ${(props) => (props.active ? props.activeColor : props.theme.colors.dimGray.plus2)};
`;

export interface UserListItemProps {
  id: string;
  avatar?: string;
  email: string;
  fullName: string;
  emailConfirmed: boolean;
  invitationLink?: string;
  phoneNumber?: string;
  title?: string;
  isPoc: boolean;
  locations?: NameId[];
}

const UserListItem: React.FC<UserListItemProps> = ({
  id,
  avatar,
  email,
  fullName,
  title,
  emailConfirmed,
  invitationLink,
  phoneNumber,
  isPoc,
  locations,
}) => {
  const detail = phoneNumber ? `${email} - ${phoneNumber}` : email;
  const [sendDashboardInviteEmail] = useMutation<boolean, SendMarketplaceOnboardingEmailRequest>(
    SEND_DASHBOARD_INVITE_EMAIL
  );

  const onConfirmDashboardInviteEmail = async (userId: string) => {
    try {
      const success = await sendDashboardInviteEmail({
        variables: { userId },
      });
      if (success) {
        snackbar.success({
          message: `Dashboard invite email sent`,
        });
      } else {
        snackbar.error({
          message: `Dashboard invite email not sent`,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to send email`);
    }
  };

  const confirmDashboardInviteEmail = async (userId: string) => {
    confirm({
      title: `Send Dashboard Invite`,
      icon: <MailOutlined />,
      okType: 'primary',
      okText: `Yes, send email`,
      content: `Would you like to send a dashboard invite to this user?`,
      onOk() {
        onConfirmDashboardInviteEmail(userId);
      },
    });
  };

  return (
    <UserListItemWrapper>
      <UserDetailCol>
        <AccountInfoCard avatar={avatar} detail={detail} accountName={fullName} title={title} />
      </UserDetailCol>
      <LocationCol>
        <>
          {locations?.map((location: NameId) => (
            <TagLabel color="default">{location.name}</TagLabel>
          ))}
        </>
      </LocationCol>
      {isPoc && (
        <IconCol>
          {' '}
          <TagLabel color={'greenLight'}>POC</TagLabel>
        </IconCol>
      )}
      <IconCol>
        <Tooltip title="Send Dashboard Invite">
          <IconButtonStyled
            icon="email"
            size="small"
            onClick={() => {
              confirmDashboardInviteEmail(id);
            }}
            active={true}
            activeColor={mainTheme.colors.purpleNavi.plus1}
          />
        </Tooltip>
      </IconCol>
      <IconCol>
        <Tooltip title="Copy Invitation Link">
          <IconButtonStyled
            icon="clipboard"
            size="small"
            onClick={() => {
              copyTextToClipboard(invitationLink || 'no link to copy');
              message.info('Invitation Link copied to clipboard');
            }}
            active={!emailConfirmed}
            activeColor={mainTheme.colors.purpleNavi.plus1}
            disabled={emailConfirmed || !invitationLink}
          />
        </Tooltip>
      </IconCol>
    </UserListItemWrapper>
  );
};

export default UserListItem;
