import React from 'react';

import { useMutation, useQuery } from '@apollo/client';

import styled from 'styled-components';

import { CHANGE_NOTE, ChangeNoteRequest, ChangeNoteResponse } from '../../../graphql/mutations/changeCustomerNote';
import { GET_CUSTOMER_NOTE } from '../../../graphql/query/getCustomerNote';
import { Heading4 } from '../Headings/Headings.styled';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 24px 16px 0;
`;

const TextAreaField = styled.textarea`
  width: 100%;
  padding: 16px 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border-radius: '16px';
  box-shadow: ${(props) => props.theme.shadows.l};
  border: solid 1px rgba(0, 0, 0, 0.07);
  background-color: ${(props) => props.theme.colors.white};
  &:focus {
    outline: 0;
    border: solid 1px ${(props) => props.theme.colors.purpleNavi.plus2};
  }
`;

interface CustomerNotesProps {
  organizationId: string;
}

export const CustomerNotes: React.FC<CustomerNotesProps> = ({ organizationId }) => {
  const { data, refetch } = useQuery(GET_CUSTOMER_NOTE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId: organizationId,
    },
  });
  const [changeNote] = useMutation<ChangeNoteResponse, ChangeNoteRequest>(CHANGE_NOTE);
  const [note, setNote] = React.useState(data?.getCustomerNote || '');
  const changeText = (event: { target: { value: any } }) => {
    setNote(event.target.value);
  };

  React.useEffect(() => {
    setNote(data?.getCustomerNote || '');
  }, [data]);

  const handleBlur = () => {
    changeNote({
      variables: {
        organizationId: organizationId,
        customerNote: note,
      },
    });
    refetch();
  };

  return (
    <div>
      <HeaderContainer>
        <Heading4>Notes</Heading4>
      </HeaderContainer>
      <TextAreaField rows={10} onBlur={handleBlur} value={note} onChange={changeText}></TextAreaField>
    </div>
  );
};
