import { gql, useQuery } from '@apollo/client';

interface MarketplaceMetricsRequest {
  organizationIds: string[];
  locationIds: string[];
  startDate: Date;
  endDate: Date;
}

export enum HealthState {
  Great = 'Great',
  Okay = 'Okay', // Deprecated
  Poor = 'Poor', // Deprecated
  AbleToPost = 'AbleToPost',
  UnableToPost = 'UnableToPost',
}

interface HealthStatus {
  health: HealthState;
  reasons: string[];
  updatedAt?: string | null;
}
export interface MarketplaceMetrics {
  name: string;
  totalPosts: number;
  activePosts: number;
  leads: number;
  installedAt: string | null;
  sales: number;
  revenue: number;
  healthStatus?: HealthStatus;
  profileId?: string;
}

interface MarketplaceMetricsResponse {
  getMarketplaceMetrics: MarketplaceMetrics[];
}

interface MarketplaceMetricsParams {
  marketplaceMetricsRequest: MarketplaceMetricsRequest;
}

const GET_MARKETPLACE_METRICS = gql`
  query getMarketplaceMetrics($marketplaceMetricsRequest: MarketplaceMetricsRequest!) {
    getMarketplaceMetrics(input: $marketplaceMetricsRequest) {
      name
      totalPosts
      activePosts
      leads
      sales
      revenue
      installedAt
      profileId
      healthStatus {
        health
        reasons
        updatedAt
      }
    }
  }
`;

export function useGetMarketplaceMetrics(params: MarketplaceMetricsParams) {
  const { data, error, loading } = useQuery<MarketplaceMetricsResponse, MarketplaceMetricsParams>(
    GET_MARKETPLACE_METRICS,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );
  return {
    marketplaceMetrics: data?.getMarketplaceMetrics || [],
    error,
    loading,
  };
}
