import React, { useContext } from 'react';

import styled from 'styled-components';

import { SideDrawerContext } from '../../../context/SideDrawerProvider';
import { RoleEnum } from '../../../models/session';
import { Button } from '../../ui/Buttons/Button';
import { HasPermission } from '../../ui/Security';
import { Spacer } from '../../ui/Spacer';
import { LabelBodyB } from '../../ui/Text/Text.styled';

const OrganizationLayoutWrapper = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.header`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const BodyContainer = styled.section`
  height: 100%;
  display: flex;
`;

const SideContent = styled.aside`
  height: 100%;
  width: 240px;
  min-width: 240px;
  overflow-y: scroll;
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
`;

const LayoutContent = styled.section`
  height: 100%;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 12px 16px;
  display: flex;
  flex-direction: row;
`;

const TitleContainer = styled.div`
  text-align: left;
  margin-left: 16px;
`;

export interface OrganizationLayoutProps {
  leftContent?: JSX.Element;
  title?: string;
}

export const OrganizationLayout: React.FC<OrganizationLayoutProps> = ({ children, leftContent, title }) => {
  const sideDrawerContext = useContext(SideDrawerContext);
  const newOrganizationHandler = () => {
    sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.newOrganization);
  };

  const newPartnerHandler = () => {
    sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.newPartner);
  };

  return (
    <OrganizationLayoutWrapper>
      <HeaderContainer>
        <div></div>
        <ButtonContainer>
          <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
            <Button btnType="secondary" size="small" type="button" onClick={newPartnerHandler}>
              ADD PARTNER
            </Button>
          </HasPermission>
          <Spacer type="inline" size="xs" />
          <HasPermission allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}>
            <Button btnType="secondary" size="small" type="button" onClick={newOrganizationHandler}>
              ADD ORGANIZATION
            </Button>
          </HasPermission>
        </ButtonContainer>
      </HeaderContainer>
      <BodyContainer>
        <SideContent>
          <Spacer type="stack" size="xs" />
          <TitleContainer>
            <LabelBodyB>{title ?? 'Organizations'}</LabelBodyB>
          </TitleContainer>
          <Spacer type="stack" size="xs" />
          {leftContent}
        </SideContent>
        <LayoutContent>{children}</LayoutContent>
      </BodyContainer>
    </OrganizationLayoutWrapper>
  );
};
