import React from 'react';

import { tagLabelColors, TagLabelStyled } from './TagLabel.styled';

export interface TagLabelProps {
  // different label styles
  color?:
    | 'default'
    | 'purpleLight'
    | 'purpleDark'
    | 'greenLight'
    | 'greenMedium'
    | 'greenDark'
    | 'aquamarine'
    | 'red'
    | 'dimgray'
    | 'orange'
    | 'viridianGreen'
    | 'redVariant';
  children?: React.ReactNode;
  onClick?: () => void;
}

export const TagLabel: React.FC<TagLabelProps> = (props: TagLabelProps) => {
  const styleProps = tagLabelColors[props.color || 'default'];
  return (
    <TagLabelStyled {...styleProps} onClick={props.onClick}>
      {props.children}
    </TagLabelStyled>
  );
};
TagLabel.defaultProps = {
  color: 'default',
};
