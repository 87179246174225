import React from 'react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FacebookSync, GoogleSync } from '../../../api/syncLocations.api';
import { Button } from '../../../components/ui/Buttons/Button';
import SimpleCard from '../../../components/ui/SimpleCard';
import { snackbar } from '../../../components/ui/Snackbar';
import { LabelBodyB } from '../../../components/ui/Text/Text.styled';
import FileUploader from '../../../components/ui/UploadButton/UploadFile';
import { HasPermission } from '../../../components/ui/Security';
import { RoleEnum } from '../../../models/session';

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
`;

const SystemSettingsWrapper = styled.div`
  padding: 40px;
`;

const LabelBodyBStyled = styled(LabelBodyB)`
  color: ${(props) => props.theme.colors.dimGray.minus1};
`;

const onFacebookSync = async () => {
  snackbar.info({ message: 'syncing', autoClose: 1000 });
  await FacebookSync();
  snackbar.success({ message: 'Successfully synced facebook accounts', autoClose: 3000 });
};

const onLesaSync = async () => {
  snackbar.info({ message: 'syncing', autoClose: 1000 });
  try {
    await GoogleSync();
    snackbar.success({ message: 'Successfully synced Lesa dealer', autoClose: 3000 });
  } catch (err: any) {
    Sentry.captureException(err);
    snackbar.error({ message: err?.response?.data?.message || err.message, autoClose: 3000 });
  }
};

const onGoogleSync = async () => {
  snackbar.info({ message: 'syncing', autoClose: 1000 });
  try {
    await GoogleSync();
    snackbar.success({ message: 'Successfully synced google locations', autoClose: 3000 });
  } catch (err: any) {
    Sentry.captureException(err);
    snackbar.error({ message: err?.response?.data?.message || err.message, autoClose: 3000 });
  }
};

export const SystemSettings = () => {
  const history = useHistory();
  const onConnectToGoogle = async () => {
    history.push('/auth/google');
  };

  return (
    <SystemSettingsWrapper>
      <SimpleCard style={{ maxWidth: '500px' }} topHeader="Business Integrations">
        <div style={{ padding: '16px' }}>
          <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
            <RowContainer>
              <LabelBodyBStyled>Facebook Sync</LabelBodyBStyled>
              <Button btnType="secondary" size="small" onClick={onFacebookSync}>
                Sync
              </Button>
            </RowContainer>
            <RowContainer>
              <LabelBodyBStyled>Google Sync</LabelBodyBStyled>
              <Button btnType="secondary" size="small" onClick={onGoogleSync}>
                Sync
              </Button>
            </RowContainer>
            <RowContainer>
              <LabelBodyBStyled>Upload Google Listing Report</LabelBodyBStyled>
              <FileUploader />
            </RowContainer>
          </HasPermission>
          <RowContainer>
            <LabelBodyBStyled>Lesa Sync</LabelBodyBStyled>
            <Button btnType="secondary" size="small" onClick={onLesaSync}>
              Sync
            </Button>
          </RowContainer>
        </div>
      </SimpleCard>
      <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
        <SimpleCard style={{ maxWidth: '500px' }} topHeader="System Settings">
          <div style={{ padding: '16px' }}>
            <RowContainer>
              <LabelBodyBStyled>Connect Google Account</LabelBodyBStyled>
              <Button btnType="secondary" size="small" onClick={onConnectToGoogle}>
                Connect
              </Button>
            </RowContainer>
          </div>
        </SimpleCard>
      </HasPermission>
    </SystemSettingsWrapper>
  );
};
