import React from 'react';
import { Tooltip } from 'antd';
import { PostMetric } from '../../../../graphql/query/inventoryByLocationId';
import { AccountIcon } from '../../../Menu/AccountMenu/AccountMenuItem/AccountMenuItem.styled';
import { Spacer } from '../../Spacer';

export enum SocialAccount {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Google = 'google',
  FacebookMarketplace = 'facebook_marketplace',
  TikTok = 'tik_tok',
}

export const hasAccount = (postMetrics: PostMetric[], socialAccount: SocialAccount) => {
  return postMetrics.some((postMetric) => postMetric.source === socialAccount);
};

const icons: Record<SocialAccount, string> = {
  facebook: 'ic ic_facebook',
  instagram: 'ic ic_instagram',
  google: 'ic ic_google',
  facebook_marketplace: 'ic ic_fb_marketplace_filled',
  tik_tok: 'ic ic_tiktok',
};
const PostedAccount: React.FC<{ post: PostMetric }> = ({ post }) => {
  return (
    <React.Fragment>
      <Tooltip
        title={() => {
          if (!post.link) return undefined;

          return (
            <a href={post.link} target="_blank" rel="noreferrer">
              View Post
            </a>
          );
        }}
        placement="top"
      >
        <AccountIcon className={icons[post.source]} />
      </Tooltip>
      <Spacer type="inline" size="xxs" />
    </React.Fragment>
  );
};

export const VehicleSocialAccounts: React.FC<{ postMetrics: PostMetric[] }> = ({ postMetrics: postMetric }) => {
  return postMetric?.length ? (
    <div style={{ display: 'flex' }}>
      {postMetric.map((postMetric) => {
        return <PostedAccount post={postMetric} />;
      })}
    </div>
  ) : (
    <div>Not posted yet</div>
  );
};
