import React, { useEffect, useState } from 'react';

import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';

import { ChartData, ChartDataSets } from 'chart.js';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  MetricType,
  PostMetricOverTime,
  useGetPostMetricsOverTime,
} from '../../../../graphql/query/getPostMetricOverTime';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { capitalizeOnlyFirstLetter } from '../../../../utils/formatText.utils';
import { intToString } from '../../../utils/convert.utils';
import { BaseChart } from '../../LocationInsights/LineChart';
import { Spacer } from '../../Spacer';
import { LabelBodyB } from '../../Text/Text.styled';
import { SocialAccount } from '../../VehicleColumn/modeViews/VehicleSocialAccounts';
import { CardStyled } from './Components.styles';
import { SocialAccountRadioGroup } from './SocialAccountRadioGroup';

const HeadingGraph = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
enum BUCKET {
  DAILY = 'daily',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
}
interface GraphPostProps {
  dashboardFilters: DashboardFilter;
}

const labelColor: Record<Exclude<SocialAccount, 'google'>, Record<MetricType, string>> = {
  facebook: {
    view: '#474ea0',
    click: '#6e77d7',
  },
  instagram: {
    view: '#9b2691',
    click: '#9b2693',
  },
  facebook_marketplace: {
    view: '#DF4778',
    click: '#DF4778',
  },
  tik_tok: {
    view: '#060606',
    click: '#353333',
  },
};

export const GraphPost: React.FC<GraphPostProps> = ({ dashboardFilters }) => {
  const [account, setAccount] = useState<SocialAccount | 'all'>('all');
  const [labels, setLabels] = useState<string[]>([]);
  const [dataSet, setDataSet] = useState<ChartDataSets[]>([]);
  const { postMetricsOverTime } = useGetPostMetricsOverTime({
    postMetricsOverTimeRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      platforms: account === 'all' ? ['facebook_marketplace', 'facebook', 'instagram', 'tik_tok'] : [account],
      locationIds: dashboardFilters.locationIds,
      postTypes: dashboardFilters.postTypes,
    },
  });
  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setAccount(value);
  };

  useEffect(() => {
    if (postMetricsOverTime.length > 0) {
      setDataSet(generateData(postMetricsOverTime));
      setLabels(formatLabel(postMetricsOverTime[0].labels, postMetricsOverTime[0].bucket));
    }
  }, [postMetricsOverTime]);

  const data: ChartData = {
    labels: labels,
    datasets: dataSet,
  };

  const config: Chart.ChartConfiguration = {
    type: 'line',
    data: data,
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                return intToString(Number(value));
              },
            },
          },
        ],
      },
      legend: {
        position: 'left',
        align: 'start',
        labels: {
          padding: 4,
          boxWidth: 6,
          fontSize: 12,
          usePointStyle: true,
        },
      },
    },
  };
  return (
    <CardStyled>
      <HeadingGraph>
        <LabelBodyB>Post Metrics</LabelBodyB>
        <SocialAccountRadioGroup
          showAllOption
          defaultValue={'all'}
          onChange={handleChangeCondition}
          selected={account}
        />
      </HeadingGraph>
      <Spacer type={'stack'} size={'xs'} />
      <BaseChart config={config} />
    </CardStyled>
  );
};

const generateData = (postMetricsOverTime: PostMetricOverTime[]): ChartDataSets[] => {
  const datasets = postMetricsOverTime.map<ChartDataSets>((postMetric) => {
    const label = match(postMetric.platform)
      .with(SocialAccount.Facebook, () => `Facebook ${capitalizeOnlyFirstLetter(postMetric.metricType)}s`)
      .with(SocialAccount.FacebookMarketplace, () => `Facebook Marketplace Clicks`)
      .with(SocialAccount.Instagram, () => `Instagram ${capitalizeOnlyFirstLetter(postMetric.metricType)}s`)
      .with(SocialAccount.TikTok, () => `TikTok ${capitalizeOnlyFirstLetter(postMetric.metricType)}s`)
      .otherwise(() => '');

    const dataset: ChartDataSets = {
      label,
      data: postMetric.data,
      fill: false,
      borderColor: labelColor[postMetric.platform][postMetric.metricType],
      backgroundColor: labelColor[postMetric.platform][postMetric.metricType],
      borderCapStyle: 'butt',
      borderDash: [5, postMetric.metricType === 'click' ? 3 : 0],
      lineTension: 0.1,
    };
    return dataset;
  });
  return datasets;
};

const formatLabel = (labels: string[], bucket: string) => {
  let format = '';
  if (bucket === BUCKET.DAILY) {
    format = 'DD MMM YY';
  } else {
    if (bucket === BUCKET.MONTHLY) format = 'MMM YYYY';
    else format = 'DD MMM';
  }
  const newLabels = labels.map((label) => {
    return moment(label).format(format);
  });
  return newLabels;
};
