import { gql, useQuery } from '@apollo/client';

import { DashboardBaseInput } from './getTopPosts';

interface CarsForSaleMetricsOverTimeRequest extends DashboardBaseInput {}

export type CarForSaleMetricType = 'impressions' | 'calls' | 'clicks' | 'messages';
export interface CarForSaleMetric {
  labels: string[];
  data: number[];
  metricType: CarForSaleMetricType;
}

interface CarsForSaleMetricsResponse {
  getCarsForSaleMetricsOverTime: CarForSaleMetric[];
}

interface CarsForSaleMetricsParams {
  carsForSaleMetricsOverTimeRequest: CarsForSaleMetricsOverTimeRequest;
}

const CAR_FOR_SALE_METRICS = gql`
  query getCarsForSaleMetricsOverTime($carsForSaleMetricsOverTimeRequest: CarsForSaleMetricsOverTimeRequest!) {
    getCarsForSaleMetricsOverTime(input: $carsForSaleMetricsOverTimeRequest) {
      labels
      data
      metricType
    }
  }
`;

export function useCarsForSaleMetrics(params: CarsForSaleMetricsParams) {
  const { data, error, loading } = useQuery<CarsForSaleMetricsResponse, CarsForSaleMetricsParams>(
    CAR_FOR_SALE_METRICS,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );
  return {
    carsForSaleMetrics: data?.getCarsForSaleMetricsOverTime || [],
    error,
    loading,
  };
}
