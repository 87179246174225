import React from 'react';

import styled from 'styled-components';

import { Heading1 } from '../../../components/ui/Headings/Headings.styled';

const LeftWrapper = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftContainer = styled.div`
  width: 376px;
`;

const WhiteHeader = styled(Heading1)`
  text-align: left;
  color: ${(props) => props.theme.colors.white};
`;

const LeftContentDescription = styled.p`
  text-align: left;
  color: ${(props) => props.theme.colors.white};
`;

const LeftLoginContent: React.FC = ({ children }) => (
  <LeftWrapper>
    <LeftContainer>
      <WhiteHeader>
        Reputation <br />
        Management <br />
        Personal <br />
        Assistant
      </WhiteHeader>
      <LeftContentDescription>{children}</LeftContentDescription>
    </LeftContainer>
  </LeftWrapper>
);
export default LeftLoginContent;
