import React, { useEffect, useState } from 'react';

import { ChartData, ChartDataSets } from 'chart.js';

import {
  CarForSaleMetric,
  CarForSaleMetricType,
  useCarsForSaleMetrics,
} from '../../../../graphql/query/getCarForSaleMetrics';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { intToString } from '../../../utils/convert.utils';
import { BaseChart } from '../../LocationInsights/LineChart';
import { CardStyled } from './Components.styles';

interface CarsForSaleMetricsGraphsProps {
  dashboardFilters: DashboardFilter;
}

const labelColor: Record<CarForSaleMetricType, string> = {
  impressions: '#1c14b8',
  messages: '#1c14b8',
  calls: '#d1361b',
  clicks: '#ede72b',
};

const labelTitle: Record<CarForSaleMetricType, string> = {
  impressions: 'Google VDP Impressions',
  messages: 'Messages',
  calls: 'Calls',
  clicks: 'Dealer VDP Clicks',
};

export const CarsForSaleMetricsGraphs: React.FC<CarsForSaleMetricsGraphsProps> = ({ dashboardFilters }) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [metricsDataSet, setMetricsDataSet] = useState<ChartDataSets[]>([]);
  const { carsForSaleMetrics } = useCarsForSaleMetrics({
    carsForSaleMetricsOverTimeRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      platforms: [],
      locationIds: dashboardFilters.locationIds,
      postTypes: [],
    },
  });

  useEffect(() => {
    if (carsForSaleMetrics.length > 0) {
      setMetricsDataSet(generateData(carsForSaleMetrics));
      setLabels(carsForSaleMetrics[0].labels);
    }
  }, [carsForSaleMetrics]);

  const metricsData: ChartData = {
    labels: labels,
    datasets: metricsDataSet,
  };

  const config = (data: ChartData): Chart.ChartConfiguration => {
    return {
      type: 'line',
      data: data,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value) {
                  return intToString(Number(value));
                },
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'center',
          labels: {
            padding: 16,
            boxWidth: 4,
            fontSize: 12,
            usePointStyle: true,
          },
        },
      },
    };
  };
  return metricsDataSet.length !== 0 ? (
    <CardStyled>
      <BaseChart config={config(metricsData)} />
    </CardStyled>
  ) : null;
};

const generateData = (carsForSaleMetrics: CarForSaleMetric[]): ChartDataSets[] => {
  const datasets: ChartDataSets[] = [];
  carsForSaleMetrics.forEach((carsForSaleMetric) => {
    if (carsForSaleMetric.data.length !== 0) {
      const dataset: ChartDataSets = {
        label: labelTitle[carsForSaleMetric.metricType],
        data: carsForSaleMetric.data,
        fill: false,
        borderColor: labelColor[carsForSaleMetric.metricType],
        backgroundColor: labelColor[carsForSaleMetric.metricType],
        borderCapStyle: 'square',
        borderDash: [5, 3],
        lineTension: 0.1,
      };
      datasets.push(dataset);
    }
  });
  return datasets;
};
