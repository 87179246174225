import React, { useRef, useState } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { GqlMutationError } from '../../../../graphql/gql-error';
import { UPSERT_LOCATION, UpsertLocationParams } from '../../../../graphql/mutations/upsertLocation';
import {
  InventorySource,
  LocationItem,
  LocationProductEnum,
  LOCATIONS_BY_ORG_ID_NAME,
} from '../../../../graphql/query/locationsByOrgId';
import {
  TikByOrgIdParams,
  TIKTOK_CREATOR_BY_ORG_ID,
  TikTokCreatorByOrgIdResponse,
} from '../../../../graphql/query/tikTokCreatorByOrganizationId';
import {
  USERS_BY_ORG_ID,
  UsersByOrgIdParams,
  UsersByOrgIdResponse,
} from '../../../../graphql/query/usersByOrganizationId';
import { FormRef } from '../../../../models/form';
import { ToggleBoxOptions } from '../../../utils/enums.utils';
import { formatSettings } from '../../../utils/product.utils';
import { displayGqlErrors } from '../../ErrorList';
import SideDrawer from '../../SideDrawer';
import { snackbar } from '../../Snackbar';
import { LabelBodyB } from '../../Text/Text.styled';
import { NewLocationForm, UpsertLocationFormValue } from './NewLocationForm';
import { useParams } from 'react-router-dom';
import { AccountType, useAssignableAccountsQuery } from '../../../../graphql/query/assignableAccounts';
import { SelectOption } from '../../FormSelectField/FormSelectField';

interface NewLocationSideDrawerProps {
  isSideDrawerVisible: boolean;
  toggleSideDrawer: (value?: boolean) => void;
  updateObject: LocationItem;
}

const FormContainer = styled.section`
  margin: 16px;
  width: 100%;
`;

const SectionTitleContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 32px;
`;

const NewLocationSideDrawer: React.FC<NewLocationSideDrawerProps> = ({
  isSideDrawerVisible,
  toggleSideDrawer,
  updateObject,
}) => {
  const formRef = useRef<FormRef<UpsertLocationFormValue>>(null);
  const { assignableAccounts: businessCenter } = useAssignableAccountsQuery(
    updateObject ? updateObject.id : '',
    AccountType.TikTokBusinessCenter
  );
  const { assignableAccounts: googleLocations } = useAssignableAccountsQuery(
    updateObject ? updateObject.id : '',
    AccountType.GoogleLocation
  );
  const { assignableAccounts: tagManagerAccounts } = useAssignableAccountsQuery(
    updateObject ? updateObject.id : '',
    AccountType.GoogleTagManager
  );

  const { organizationId } = useParams<{ organizationId: string }>();
  const { assignableAccounts: facebookPages } = useAssignableAccountsQuery(
    updateObject ? updateObject.id : '',
    AccountType.FacebookPage
  );
  const { assignableAccounts: facebookAdAccounts } = useAssignableAccountsQuery(
    updateObject ? updateObject.id : '',
    AccountType.FacebookAdAccount
  );

  const { assignableAccounts: lesaDealers } = useAssignableAccountsQuery(
    updateObject ? updateObject.id : '',
    AccountType.LESA
  );

  const { data: users } = useQuery<UsersByOrgIdResponse, UsersByOrgIdParams>(USERS_BY_ORG_ID, {
    variables: {
      organizationId: organizationId!,
      onlyActive: true,
    },
  });

  const { data: tikTokCreators } = useQuery<TikTokCreatorByOrgIdResponse, TikByOrgIdParams>(TIKTOK_CREATOR_BY_ORG_ID, {
    variables: {
      organizationId: organizationId!,
    },
  });

  const [upsertLocation] = useMutation<any, UpsertLocationParams>(UPSERT_LOCATION, {
    refetchQueries: [LOCATIONS_BY_ORG_ID_NAME],
  });
  const products = updateObject?.products?.filter((product) => product.enabled).map((product) => product.slug) ?? [];
  const productSettings = formatSettings(updateObject?.products ?? []);
  const [activatedProducts, setActivatedProducts] = useState<LocationProductEnum[]>(products);
  const initialValues: UpsertLocationFormValue = {
    // Required Fields
    name: updateObject?.name,
    address: updateObject?.address,
    city: updateObject?.city,
    state: updateObject?.state,
    zip: updateObject?.zip,
    products: products,
    country: updateObject?.country,
    type: updateObject?.type,
    // Optional Fields
    id: updateObject?.id,
    serviceCenterUrl: updateObject?.serviceCenterUrl,
    facebookPageId: updateObject?.facebookPage?.pageId,
    tikTokBusinessCenterId: updateObject?.businessCenter?.id,
    tikTokStoreId: updateObject?.tikTokStoreId,
    tikTokPixelId: updateObject?.tikTokPixelId,
    facebookPixelId: updateObject?.facebookPixelId,
    googleLocationId: updateObject?.googleLocation?.id,
    tagManagerAccountId: updateObject?.tagManagerStatus?.id,
    facebookAdAccountId: updateObject?.facebookAdAccountId,
    website: updateObject?.website,
    externalDealerId: updateObject?.dealer?.inventoryUrl ?? updateObject?.dealer?.externalId,
    autoPostsEnabled: Boolean(updateObject?.autoPostsEnabled) ? 1 : 0 ?? ToggleBoxOptions.No,
    crmReportsConnected: Boolean(updateObject?.crmReportsConnected) ? 1 : 0 ?? ToggleBoxOptions.No,
    leadRevivalTextEnabled: Boolean(updateObject?.leadRevivalTextEnabled) ? 1 : 0 ?? ToggleBoxOptions.No,
    inventoryProvider: updateObject?.inventoryProvider,
    keywords: updateObject?.keywords,
    inventorySource: updateObject?.dealer?.source ?? InventorySource.MARKETCHECK,
    dealerType: updateObject?.dealer?.type,
    shopId: updateObject?.shopId,
    catalogId: updateObject?.catalogId,
    dealerMessage: updateObject?.dealerMessage,
    syncNonVinListings: updateObject?.dealer?.syncNonVinListings ? 1 : 0 ?? ToggleBoxOptions.No,
    syncOwnedListings: updateObject?.dealer?.syncOwnedListings ? 1 : 0 ?? ToggleBoxOptions.No,
    fbFooter: updateObject?.customTemplate?.fbFooter,
    googleFooter: updateObject?.customTemplate?.googleFooter,
    marketplaceFooter: updateObject?.customTemplate?.marketplaceFooter,
    marketplaceTemplate: updateObject?.marketplaceTemplate,
    connectedMarketplace: updateObject?.connectedMarketplace ? 1 : 0 ?? ToggleBoxOptions.No,
    archiveAt: updateObject?.archiveAt,
    archivedAt: updateObject?.archivedAt,
    carsForSaleEnabled: productSettings.google?.carsForSaleEnabled ? 1 : 0,
    dailyPostNumber: productSettings.facebook_marketplace?.dailyPostNumber,
    imageRequirement: productSettings.facebook_marketplace?.imageRequirement,
    marketplaceInventoryDistribution: productSettings.facebook_marketplace?.inventoryDistribution,
    dealershipNumber: updateObject?.dealershipNumber,
    fbMarketplaceLocation: updateObject?.fbMarketplaceLocation,
    homenetId: updateObject?.homenetId,
    tikTokCreatorId: updateObject?.tikTokCreator?.id ?? '',
    tagManagerStatus: updateObject?.tagManagerStatus,
    marketplaceLicenses: updateObject?.marketplaceLicenses,
    crm: updateObject?.crm,
    contactUsUrl: updateObject?.contactUsUrl,
    serviceUrl: updateObject?.serviceUrl,
    serviceNumber: updateObject?.serviceNumber,
    tradeInUrl: updateObject?.tradeInUrl,
    leaseUrl: updateObject?.leaseUrl,
    marketingEmail: updateObject?.marketingEmail,
    replyToEmail: updateObject?.replyToEmail,
    adfEmail: updateObject?.adfEmail,
    crmPassword: updateObject?.crmPassword,
    crmUsername: updateObject?.crmUsername,
    merchantCenterId: updateObject?.merchantCenter?.id,
    merchantCenterFeedApproved: updateObject?.merchantCenter?.feedApproved,
    googleAdAccountId: updateObject?.googleAdAccount?.id,
  };

  const fbPageOptions = facebookPages.map<SelectOption>((page) => ({
    label: page.name,
    value: page.id,
  }));

  const fbAdAccountOptions = facebookAdAccounts.map<SelectOption>((adAccount) => ({
    label: adAccount.name,
    value: adAccount.id,
  }));

  const userOptions = users
    ? users.usersByOrgId
      ? users.usersByOrgId.map<SelectOption>((user) => ({
          label: user?.fullName ?? '',
          value: user.id,
          image: user?.avatar?.signedUrl,
        }))
      : []
    : [];

  const tikTokCreatorOptions = tikTokCreators
    ? tikTokCreators.tikTokCreatorByOrgId
      ? tikTokCreators.tikTokCreatorByOrgId.map<SelectOption>((tikTokCreator) => ({
          label: tikTokCreator.name ?? '',
          value: tikTokCreator.id,
          image: '',
        }))
      : []
    : [];

  const lesaOptions = lesaDealers.map<SelectOption>((lesa) => ({
    label: `${lesa.name} - ${lesa.externalId}`,
    value: lesa.externalId!,
  }));

  const googleLocationOptions = googleLocations.map<SelectOption>((location) => ({
    label: location.name,
    value: location.id,
  }));

  const tagManagerAccountOptions = tagManagerAccounts.map<SelectOption>((account) => ({
    label: account.name,
    value: account.id,
  }));

  const businessCenterOptions = businessCenter.map<SelectOption>((businessCenter) => ({
    label: businessCenter.name,
    value: businessCenter.id,
  }));

  const sourceInventoryOptions: SelectOption[] = [
    {
      label: 'MarketCheck',
      value: InventorySource.MARKETCHECK,
    },
    {
      label: 'Homenet',
      value: InventorySource.HOMENET,
    },
    {
      label: 'Edealer',
      value: InventorySource.EDEALER,
    },
    {
      label: 'DealerSpike',
      value: InventorySource.DEALERSPIKE,
    },
    {
      label: 'DX1',
      value: InventorySource.DX1,
    },
    {
      label: 'Lesa',
      value: InventorySource.LESA,
    },
  ];

  const handleFormCompleted = async (formData: UpsertLocationFormValue) => {
    const actionLabel = !!updateObject ? 'update' : 'create';
    try {
      const requestData: UpsertLocationParams = {
        data: {
          ...formData,
          products: activatedProducts,
          organizationId: organizationId!,
          id: updateObject?.id ?? '',
        },
      };

      await upsertLocation({
        variables: requestData,
      });

      snackbar.success({
        message: `Location ${actionLabel}d Successfully`,
      });

      toggleSideDrawer();
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error as ApolloError | GqlMutationError, `Unable to ${actionLabel} the Location`);
    }
  };

  return (
    <SideDrawer
      heading={!updateObject ? 'New Location' : 'Edit Location'}
      isDrawerVisible={isSideDrawerVisible}
      toggleDrawerVisible={toggleSideDrawer}
      ctaButtons={[
        {
          label: !updateObject ? 'NEW LOCATION' : 'UPDATE LOCATION',
          onClick: async () => {
            formRef.current?.submit();
          },
          isDisabled: false,
        },
      ]}
    >
      <FormContainer>
        <SectionTitleContainer>
          <LabelBodyB>Location</LabelBodyB>
        </SectionTitleContainer>
        <NewLocationForm
          ref={formRef}
          setProducts={setActivatedProducts}
          onCompleted={handleFormCompleted}
          locationId={updateObject?.id}
          fbPageOptions={fbPageOptions}
          fbAdAccountOptions={fbAdAccountOptions}
          businessCenterOptions={businessCenterOptions}
          lesaOptions={lesaOptions}
          userOptions={userOptions}
          tikTokCreatorOptions={tikTokCreatorOptions}
          googleLocationOptions={googleLocationOptions}
          tagManagerAccountOptions={tagManagerAccountOptions}
          initialValues={initialValues}
          sourceInventoryOptions={sourceInventoryOptions}
          organizationId={organizationId!}
        />
      </FormContainer>
    </SideDrawer>
  );
};

export default NewLocationSideDrawer;
