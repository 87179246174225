import { gql } from '@apollo/client';
import { Partner } from './partners';

export interface OrganizationItem {
  id: string;
  name: string;
  headquarters: string;
  domain: string;
  isActive: boolean;
  accountOwnerId: string;
  customerNotes?: string;
  pocUserId?: string;
  partner?: Partner;
  avatar?: {
    id: number;
    signedUrl: string;
  };
}
export interface OrganizationListResponse {
  organizations: OrganizationItem[];
}

export interface OrganizationListRequest {
  onlyActive?: boolean;
  partnersOnly?: boolean;
  name?: string;
}

export const OrganizationFieldsFragment = gql`
  fragment OrganizationFields on Organization {
    id
    name
    headquarters
    domain
    isActive
    accountOwnerId
    customerNotes
    pocUserId
    avatar {
      id
      signedUrl
    }
  }
`;

export const ORGANIZATION_LIST = gql`
  query organizationList($onlyActive: Boolean, $name: String, $partnersOnly: Boolean) {
    organizations(name: $name, onlyActive: $onlyActive, partnersOnly: $partnersOnly) {
      ...OrganizationFields
      partner {
        id
        name
      }
    }
  }
  ${OrganizationFieldsFragment}
`;
