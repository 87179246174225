import React from 'react';

import styled from 'styled-components';

import AvatarImage from '../AvatarImage';
import { Button } from '../Buttons/Button';
import { Heading3 } from '../Headings/Headings.styled';
import { LabelBodyR } from '../Text/Text.styled';
import { Partner } from '../../../graphql/query/partners';
import { TagLabel } from '../TagLabel';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
`;

const OrgInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 8px;
`;

const MainContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px 0 32px;
`;

const DividerVertical = styled.div`
  width: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-right: 8px;
`;

const StyledBodyR = styled.div`
  * {
    color: ${(props) => props.theme.colors.dimGray.base};
  }
`;

export interface OrganizationCardHeaderProps {
  imageSrc: string;
  companyName: string;
  cityState: string;
  isActive: boolean;
  onEditClick: () => void;
  toggleOrganizationStatus: () => void;
  partner?: Partner;
}

const OrganizationCardHeader: React.FC<OrganizationCardHeaderProps> = ({
  imageSrc,
  companyName,
  cityState,
  isActive,
  onEditClick,
  toggleOrganizationStatus,
  partner,
}) => {
  return (
    <MainContainer>
      <OrgInfoContainer>
        <AvatarImage size={'large'} src={imageSrc} nameAvatar={companyName} />
        <TextContainer>
          <Heading3>{companyName}</Heading3>
          <StyledBodyR>
            <LabelBodyR>{cityState}</LabelBodyR>
          </StyledBodyR>
          {partner && <TagLabel color={'viridianGreen'}>{partner.name}</TagLabel>}
        </TextContainer>
      </OrgInfoContainer>
      <ButtonsContainer>
        <Button btnType="secondary" onClick={onEditClick} size="small">
          EDIT
        </Button>
        <DividerVertical />
        <Button btnType="secondary" onClick={toggleOrganizationStatus} size="small">
          {isActive ? 'ARCHIVE' : 'UNARCHIVE'}
        </Button>
        <DividerVertical />
      </ButtonsContainer>
    </MainContainer>
  );
};

export default OrganizationCardHeader;
