import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { SideDrawerContext } from '../../../context/SideDrawerProvider';
import { Heading4 } from '../Headings/Headings.styled';
import IconButtonSimple from '../IconButtonSimple';
import UserListItem from './UserListItem';
import {
  USERS_BY_PARTNER_ID,
  UsersByPartnerIdParams,
  UsersByPartnerIdResponse,
} from '../../../graphql/query/usersByPartnerId';

const UserListWrapper = styled.section`
  width: 100%;
`;
const UserListTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const UserListContainer = styled.div`
  width: 100%;
  align-content: center;
  align-self: center;
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(322px, 49%));
  justify-content: space-between;
  margin-top: 16px;
`;
export interface PartnerUserListProps {
  partnerId: string;
  isActive: boolean;
}

const PartnerUserList: React.FC<PartnerUserListProps> = ({ partnerId, isActive }) => {
  const { data } = useQuery<UsersByPartnerIdResponse, UsersByPartnerIdParams>(USERS_BY_PARTNER_ID, {
    variables: {
      partnerId: partnerId,
      onlyActive: true,
    },
  });
  const sideDrawerContext = useContext(SideDrawerContext);
  const newUserHandler = () => sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.upsertUser);

  return (
    <UserListWrapper>
      <UserListTitleRow>
        <Heading4>Partner Account Users</Heading4>
        <IconButtonSimple disabled={!isActive} icon="plus" size="medium" onClick={newUserHandler} />
      </UserListTitleRow>
      <UserListContainer>
        {data?.usersByPartnerId.map((user) => (
          <UserListItem
            key={user.id}
            id={user.id}
            title={user.title}
            avatar={user?.avatar?.signedUrl}
            fullName={user?.fullName || ''}
            email={user.username || ''}
            phoneNumber={user.phoneNumber || ''}
            emailConfirmed={user.mailConfirmed}
            invitationLink={user?.invitationLink}
            isPoc={user.isPartnerPoc}
            locations={user?.locations}
          />
        ))}
      </UserListContainer>
    </UserListWrapper>
  );
};

export default PartnerUserList;
