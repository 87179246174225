import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useSession } from '../../../context/AppStateProvider';
import { useMyProfileQuery } from '../../../graphql/query/userProfile';
import { RoleEnum } from '../../../models/session';
import AvatarImage from '../../ui/AvatarImage';
import { Spacer } from '../../ui/Spacer';
import NavigationLink from './NavigationLink';

const StyledUserSideNavigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

const Center = styled.div`
  align-self: center;
`;

export const UserSideNavigation: React.FC = () => {
  const { session } = useSession();
  const { data } = useMyProfileQuery();
  return (
    <>
      <StyledUserSideNavigation>
        <NavigationLink
          href="/app/organization"
          icon="organization"
          tooltipText="Organizations"
          allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}
        />
        <NavigationLink
          href="/app/partner"
          icon="handshake"
          tooltipText="Partners"
          allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}
        />
        <NavigationLink
          href={`/app/users/${session?.organizationId}`}
          icon="people"
          tooltipText="Users"
          allowedRoles={[RoleEnum.Admin]}
        />
        <NavigationLink
          href={`/app/inventory/${session?.organizationId}`}
          icon="inventory"
          tooltipText="Inventory"
          allowedRoles={[RoleEnum.Admin]}
        />
        <NavigationLink href="/app/queue" icon="queue" tooltipText="Queue" allowedRoles={[RoleEnum.SuperAdmin]} />
        <NavigationLink
          href="/app/dashboard"
          icon="dashboard"
          tooltipText="Dashboard"
          forbiddenRoles={[RoleEnum.Member]}
        />
        <NavigationLink
          href="/app/settings"
          icon="menuOptions"
          allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}
        />
        <NavigationLink
          href="/app/settings/user/google"
          icon="menuOptions"
          forbiddenRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner, RoleEnum.Member]}
        />
        <Spacer type="stack" size="xs" />
        <Center>
          {/* @ts-ignore */}
          <Link to={'/app/profile'}>
            <AvatarImage
              size={'small'}
              nameAvatar={data?.myInfo.fullName || session?.username}
              src={data?.myInfo.avatar?.signedUrl}
            />
          </Link>
        </Center>
      </StyledUserSideNavigation>
    </>
  );
};
