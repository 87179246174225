import React from 'react';

import { Radio } from 'antd';
import { RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio';

import styled from 'styled-components';

import { SocialAccount } from '../../VehicleColumn/modeViews/VehicleSocialAccounts';

const SocialWrapper = styled.div`
  align-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 4px;
`;

interface SocialAccountRadioGroupProp extends AntRadioGroupProps {
  showAllOption?: boolean;
  selected: SocialAccount | 'all';
}
export const SocialAccountRadioGroup = React.forwardRef<HTMLDivElement, SocialAccountRadioGroupProp>(
  ({ showAllOption, selected, ...props }, ref) => (
    <Radio.Group ref={ref} {...props}>
      {showAllOption && (
        <Radio.Button key={'all'} value={'all'}>
          All
        </Radio.Button>
      )}
      <Radio.Button key={'facebook_marketplace'} value={'facebook_marketplace'}>
        <SocialWrapper>
          <i className="ic ic_fb_marketplace_filled" />
        </SocialWrapper>
      </Radio.Button>
      <Radio.Button key={'facebook'} value={'facebook'}>
        <SocialWrapper>
          <i className="ic ic_facebook" />
        </SocialWrapper>
      </Radio.Button>
      <Radio.Button key={'instagram'} value={'instagram'}>
        <SocialWrapper>
          <i className={`ic ic_ig${selected === 'instagram' ? '_selected' : ''}`} />
        </SocialWrapper>
      </Radio.Button>
      <Radio.Button key={'tik_tok'} value={'tik_tok'}>
        <SocialWrapper>
          <i className="ic ic_tiktok" />
        </SocialWrapper>
      </Radio.Button>
    </Radio.Group>
  )
);
