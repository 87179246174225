import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';

import { ApolloProvider } from '@apollo/client';

import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from './components/StyleGuide/Global';
import { darkTheme, lightTheme } from './components/StyleGuide/themes';
import { SnackbarContainer } from './components/ui/Snackbar';
import { AppStateProvider } from './context/AppStateProvider';
import { graphqlClient } from './graphql';
import { useDarkMode } from './hooks/useDarkMode';
import RootRoutes from './routes';

const HEROKU_APP = 'herokuapp';

function App() {
  const [theme] = useDarkMode();
  const currentTheme = theme === 'light' ? lightTheme : darkTheme;

  // If anyone tries to go to the old app url once we've updated to the new host, redirect them
  const appUrl = process.env.REACT_APP_API_URL;
  if (window.location.href.includes(HEROKU_APP) && !appUrl?.includes(HEROKU_APP)) {
    window.location.href = `${appUrl}${window.location.pathname}`;
  }

  return (
    <ApolloProvider client={graphqlClient}>
      <ThemeProvider theme={currentTheme}>
        <AppStateProvider>
          <GlobalStyle />
          <div className="App">
            <RootRoutes />
          </div>
          <SnackbarContainer />
        </AppStateProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
