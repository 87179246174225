import { gql, useQuery } from '@apollo/client';

import { SocialAccount } from '../../components/ui/VehicleColumn/modeViews/VehicleSocialAccounts';
import { DashboardBaseInput } from './getTopPosts';

interface PostMetricsOverTimeRequest extends DashboardBaseInput {}

export type MetricType = 'view' | 'click';
export interface PostMetricOverTime {
  platform: Exclude<SocialAccount, 'google'>;
  labels: string[];
  data: number[];
  bucket: string;
  metricType: MetricType;
}

interface PostMetricsOverTimeResponse {
  getPostMetricsOverTime: PostMetricOverTime[];
}

interface PostMetricsOverTimeParams {
  postMetricsOverTimeRequest: PostMetricsOverTimeRequest;
}

const GET_POST_METRICS_OVER_TIME = gql`
  query getPostMetricsOverTime($postMetricsOverTimeRequest: PostMetricsOverTimeRequest!) {
    getPostMetricsOverTime(input: $postMetricsOverTimeRequest) {
      platform
      labels
      data
      bucket
      metricType
    }
  }
`;

export function useGetPostMetricsOverTime(params: PostMetricsOverTimeParams) {
  const { data, error, loading } = useQuery<PostMetricsOverTimeResponse, PostMetricsOverTimeParams>(
    GET_POST_METRICS_OVER_TIME,
    {
      variables: params,
      fetchPolicy: 'cache-and-network',
    }
  );
  return {
    postMetricsOverTime: data?.getPostMetricsOverTime || [],
    error,
    loading,
  };
}
