import React from 'react';

import { Controller, FieldValues } from 'react-hook-form';
import styled from 'styled-components';

import HelperMessage from '../HelperMessage';
import { RadioGroupFieldProps } from '../RadioGroupField/RadioGroupField';
import { RadioGroupHorizontal } from '../RadioGroupHorizontal/RadioGroupHorizontal';
import { Spacer } from '../Spacer';
import { LabelBold } from '../Text/Text.styled';

const ControlWrapper = styled.div``;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start &.with-label {
    padding-top: 32px;
  }
`;

const LabelBoldStyled = styled(LabelBold)`
  margin-right: 24px;
`;

export interface RadioGroupHorizontalFieldProps extends RadioGroupFieldProps<FieldValues> {}

function RadioGroupHorizontalField<FormValue extends FieldValues = any>(
  props: RadioGroupHorizontalFieldProps
): React.ReactElement<RadioGroupFieldProps<FormValue>> {
  const { name, defaultValue, control, label, errors, options } = props;
  return (
    <ControlWrapper>
      <ControlContainer>
        {label && <LabelBoldStyled>{label?.toUpperCase()}</LabelBoldStyled>}
        <Spacer type={'stack'} size={'xxs'} />
        <Controller
          render={({ name, onChange, value }) => (
            <RadioGroupHorizontal
              name={name}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              options={options}
            />
          )}
          name={name}
          control={control}
          defaultValue={defaultValue}
        />
      </ControlContainer>
      {(errors?.[name] && <HelperMessage type="danger" message={(errors[name] as any)?.message} />) || null}
    </ControlWrapper>
  );
}

RadioGroupHorizontalField.defaultProps = {
  label: '',
  type: 'text',
};

export default RadioGroupHorizontalField;
