import { gql } from '@apollo/client';

export interface SetToMarkSoldOnMarketplaceParams {
  vin: string;
  locationId: string;
}

export const SET_TO_MARK_SOLD_ON_MARKETPLACE = gql`
  mutation setToMarkSoldOnMarketplace($vin: String!, $locationId: String!) {
    setToMarkSoldOnMarketplace(vin: $vin, locationId: $locationId)
  }
`;
