import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';

import styled from 'styled-components';

import { SideDrawerContext } from '../../../context/SideDrawerProvider';
import {
  USERS_BY_ORG_ID,
  UsersByOrgIdParams,
  UsersByOrgIdResponse,
} from '../../../graphql/query/usersByOrganizationId';
import { Heading4 } from '../Headings/Headings.styled';
import IconButtonSimple from '../IconButtonSimple';
import UserListItem from './UserListItem';

const UserListWrapper = styled.section`
  width: 100%;
`;
const UserListTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const UserListContainer = styled.div`
  width: 100%;
  align-content: center;
  align-self: center;
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(322px, 49%));
  justify-content: space-between;
  margin-top: 16px;
`;
export interface OrganizationUserListProps {
  organizationId: string;
  isActive: boolean;
}

const OrganizationUserList: React.FC<OrganizationUserListProps> = ({ organizationId, isActive }) => {
  const { data } = useQuery<UsersByOrgIdResponse, UsersByOrgIdParams>(USERS_BY_ORG_ID, {
    variables: {
      organizationId: organizationId,
      onlyActive: true,
    },
  });
  const sideDrawerContext = useContext(SideDrawerContext);
  const newUserHandler = () => sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.upsertUser);

  return (
    <UserListWrapper>
      <UserListTitleRow>
        <Heading4>Users</Heading4>
        <IconButtonSimple disabled={!isActive} icon="plus" size="medium" onClick={newUserHandler} />
      </UserListTitleRow>
      <UserListContainer>
        {data?.usersByOrgId.map((user) => (
          <UserListItem
            key={user.id}
            id={user.id}
            title={user.title}
            avatar={user?.avatar?.signedUrl}
            fullName={user?.fullName || ''}
            email={user.username || ''}
            phoneNumber={user.phoneNumber || ''}
            emailConfirmed={user.mailConfirmed}
            invitationLink={user?.invitationLink}
            isPoc={user.isPoc}
            locations={user?.locations}
          />
        ))}
      </UserListContainer>
    </UserListWrapper>
  );
};

export default OrganizationUserList;
