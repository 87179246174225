import { gql, useQuery } from '@apollo/client';

export interface GoogleLocationInsightsParams {
  locationId: string;
  startDate: string | null;
  endDate: string | null;
}

export interface GoogleLocationInsightsResponse {
  googleLocationInsights: GmbReportLocationInsightsResponse;
}
export const GmbDimensionalMetricValueFieldsFragment = gql`
  fragment GmbDimensionalMetricValueFields on GmbDimensionalMetricValue {
    value
    metricOption
    timeDimension {
      dayOfWeek
      timeOfDay {
        hours
        minutes
        seconds
        nanos
      }
      timeRange {
        endTime
        startTime
      }
    }
  }
`;
export const GOOGLE_LOCATION_INSIGHTS = gql`
  query googleLocationInsights($locationId: String!, $startDate: String!, $endDate: String!) {
    googleLocationInsights(locationId: $locationId, startDate: $startDate, endDate: $endDate) {
      locationMetrics {
        locationName
        timeZone
        metricValues {
          metric
          totalValue {
            ...GmbDimensionalMetricValueFields
          }
          dimensionalValues {
            ...GmbDimensionalMetricValueFields
          }
        }
      }
    }
  }
  ${GmbDimensionalMetricValueFieldsFragment}
`;

export function useGoogleLocationInsights(params: GoogleLocationInsightsParams) {
  const { data, loading, error, fetchMore } = useQuery<GoogleLocationInsightsResponse, GoogleLocationInsightsParams>(
    GOOGLE_LOCATION_INSIGHTS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        ...params,
      },
    }
  );
  return {
    data: data?.googleLocationInsights,
    loading,
    error,
    fetchMore,
  };
}

export interface GmbReportLocationInsightsResponse {
  locationMetrics: GmbLocationMetrics[];
}

export interface GmbLocationMetrics {
  locationName: string;
  timeZone: string;
  metricValues: GmbMetricValue[];
}

export interface GmbMetricValue {
  metric: GmbMetricEnum;
  totalValue?: GmbDimensionalMetricValue;
  dimensionalValues?: GmbDimensionalMetricValue[];
}

export interface GmbDimensionalMetricValue {
  metricOption: GmbMetricOptionEnum;
  timeDimension: GmbTimeDimension;
  value: string;
}
export enum GmbMetricEnum {
  METRIC_UNSPECIFIED = 'METRIC_UNSPECIFIED',
  ALL = 'ALL',
  QUERIES_DIRECT = 'QUERIES_DIRECT',
  QUERIES_INDIRECT = 'QUERIES_INDIRECT',
  QUERIES_CHAIN = 'QUERIES_CHAIN',
  VIEWS_MAPS = 'VIEWS_MAPS',
  VIEWS_SEARCH = 'VIEWS_SEARCH',
  ACTIONS_WEBSITE = 'ACTIONS_WEBSITE',
  ACTIONS_PHONE = 'ACTIONS_PHONE',
  ACTIONS_DRIVING_DIRECTIONS = 'ACTIONS_DRIVING_DIRECTIONS',
  PHOTOS_VIEWS_MERCHANT = 'PHOTOS_VIEWS_MERCHANT',
  PHOTOS_VIEWS_CUSTOMERS = 'PHOTOS_VIEWS_CUSTOMERS',
  PHOTOS_COUNT_MERCHANT = 'PHOTOS_COUNT_MERCHANT',
  PHOTOS_COUNT_CUSTOMERS = 'PHOTOS_COUNT_CUSTOMERS',
  LOCAL_POST_VIEWS_SEARCH = 'LOCAL_POST_VIEWS_SEARCH',
  LOCAL_POST_ACTIONS_CALL_TO_ACTION = 'LOCAL_POST_ACTIONS_CALL_TO_ACTION',
}

export enum GmbMetricOptionEnum {
  METRIC_OPTION_UNSPECIFIED = 'METRIC_OPTION_UNSPECIFIED',
  AGGREGATED_TOTAL = 'AGGREGATED_TOTAL',
  AGGREGATED_DAILY = 'AGGREGATED_DAILY',
  BREAKDOWN_DAY_OF_WEEK = 'BREAKDOWN_DAY_OF_WEEK',
  BREAKDOWN_HOUR_OF_DAY = 'BREAKDOWN_HOUR_OF_DAY',
}

export enum GmbDayOfWeek {
  DAY_OF_WEEK_UNSPECIFIED = 'DAY_OF_WEEK_UNSPECIFIED',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface GmbTimeDimension {
  dayOfWeek: GmbDayOfWeek;
  timeOfDay: GmbTimeOfDay;
  timeRange: GmbTimeRange;
}

export interface GmbTimeOfDay {
  hours: number;
  minutes: number;
  seconds: number;
  nanos: number;
}

export interface GmbTimeRange {
  startTime: string;
  endTime: string;
}
