import React, { useEffect, useRef, useState } from 'react';

import Chart, { ChartConfiguration, ChartData, ChartPoint } from 'chart.js';

import { GmbDimensionalMetricValue, GmbMetricEnum } from '../../../graphql/query/googleLocationInsights';
import { LabelB, LabelR } from '../Text/Text.styled';

export interface BaseChartProps {
  config: ChartConfiguration;
}
export const BaseChart: React.FC<BaseChartProps> = ({ config }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart>();
  useEffect(() => {
    if (config) {
      if (chart) {
        chart.destroy();
      }
      setChart(new Chart(chartRef.current!, config));
    }
    return () => {
      chart?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);
  return <canvas ref={chartRef} />;
};

export interface GmbMetricGroup {
  total?: GmbDimensionalMetricValue;
  values?: GmbDimensionalMetricValue[];
}

export interface MetricGroupChartProps {
  metricName: GmbMetricEnum;
  metric: GmbMetricGroup;
}

export const MetricGroupChart: React.FC<MetricGroupChartProps> = ({ metricName, metric }) => {
  const [chartConfig, setChartConfig] = useState<ChartConfiguration>();

  useEffect(() => {
    const mappedData: ChartPoint[] =
      metric.values?.map((item): ChartPoint => {
        return {
          y: Number(item.value),
          x: new Date(item.timeDimension.timeRange.startTime),
        };
      }) || [];

    const chartData: ChartData = {
      datasets: [
        {
          label: getDatasetLabel(metricName),
          backgroundColor: 'blue',
          borderColor: 'blue',
          fill: false,
          data: mappedData,
        },
      ],
    };
    const config: ChartConfiguration = {
      type: 'line',
      data: chartData,
      options: {
        title: {
          display: true,
          text: getDatasetLabel(metricName),
        },
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Amount',
              },
            },
          ],
          xAxes: [
            {
              type: 'time',
              scaleLabel: {
                display: true,
                labelString: 'Date',
              },
              time: {
                displayFormats: {
                  quarter: 'DD MMM YYYY',
                },
              },
              ticks: {
                autoSkip: true,
                autoSkipPadding: 32,
                padding: 16,
              },
            },
          ],
        },
      },
    };
    setChartConfig(config);
  }, [metric, metricName]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LabelB>Total:</LabelB> &nbsp; <LabelR> {metric.total?.value}</LabelR>
      </div>
      {chartConfig && <BaseChart config={chartConfig} />}
    </>
  );
};

function getDatasetLabel(metricName: GmbMetricEnum) {
  switch (metricName) {
    case GmbMetricEnum.ACTIONS_WEBSITE:
      return 'Daily Website clicks';
    case GmbMetricEnum.VIEWS_MAPS:
      return 'Daily Map views';
    case GmbMetricEnum.VIEWS_SEARCH:
      return 'Daily Search views';
    default:
      return `Daily ${metricName}`;
  }
}
