import React, { useImperativeHandle } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormComponentProps, FormRef } from '../../../../models/form';
import TextField from '../../TextField';
import { Spacer } from '../../Spacer';
import RadioGroupField from '../../RadioGroupField';
import { ToggleBoxOptions, toggleBoxOptions } from '../../../utils/enums.utils';
import { HasPermission } from '../../Security';
import { RoleEnum } from '../../../../models/session';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().default('').required(),
  ownsOnboarding: Yup.number().nullable().default(ToggleBoxOptions.No),
  ownsOnboardingEmails: Yup.number().nullable().default(ToggleBoxOptions.No),
});

export interface EditPartnerFormValue {
  name: string;
  ownsOnboarding: ToggleBoxOptions;
  ownsOnboardingEmails: ToggleBoxOptions;
}
export interface EditPartnerFormProps extends FormComponentProps<EditPartnerFormValue> {}

const EditPartnerForm = React.forwardRef<FormRef<EditPartnerFormValue>, EditPartnerFormProps>(
  (props: EditPartnerFormProps, ref) => {
    const { handleSubmit, control, errors, formState } = useForm<EditPartnerFormValue>({
      resolver: yupResolver(ValidationSchema),
      mode: 'onChange',
      defaultValues: props.initialValues,
    });

    const onSubmit = (data: EditPartnerFormValue) => {
      props.onCompleted(data);
    };

    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit: submitHandler,
      }),
      [formState, submitHandler]
    );
    return (
      <form onSubmit={submitHandler}>
        <TextField type="text" name="name" label="NAME" placeholder="Partner Name" control={control} errors={errors} />
        <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
          <Spacer type="stack" size="m" />
          <RadioGroupField
            control={control}
            name="ownsOnboarding"
            label="Does this partner own onboarding?"
            errors={errors}
            options={toggleBoxOptions}
          />
          <Spacer type="stack" size="m" />
          <RadioGroupField
            control={control}
            name="ownsOnboardingEmails"
            label="Does this partner send their own onboarding emails?"
            errors={errors}
            options={toggleBoxOptions}
          />
        </HasPermission>
      </form>
    );
  }
);

export default EditPartnerForm;
